/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import StandardPageWrapper from '@templates/StandardPageWrapper';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Card, Col, Container, Row,
} from 'reactstrap';
import {
  v4 as uuidv4,
} from 'uuid';
import cx from 'classnames';
import useAlertBox from '@utils/useAlertBox';
import ContentDetails from '@components/ContentDetails';
import SponsorPlug from '@components/SponsorPlug';
import CTABanner from '@components/CTAContentful';
import SponsorLockup from '@components/SponsorLockup';
import BannerCredit from '@components/BannerCredit';
import MediaGallery from '@components/MediaGallery';
import withNullChecks from '@templates/HOC/withNullChecks';
import Title from '@components/Title';
import HeadSEO from '@components/Navigation/HeadSEO';
import { getHeadProps } from '@utils/navigationHelpers';
import Breadcrumbs from '@components/Navigation/Breadcrumbs';
import rendererOptions from '../../richtext-rendering/renderer-options';
import './index.scss';

export const Head = ({ data }) => {
  const { article } = data;
  const {
    pageTitle, pageDescription, pageKeywords, pageImgUrl, pageAltText,
  } = getHeadProps(article);

  return (
    <HeadSEO
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      pageKeywords={pageKeywords}
      pageImgUrl={pageImgUrl}
      pageAltText={pageAltText}
    />
  );
};

export const pageQuery = graphql`
  query ArticleBySlug($slug: String!) {
    article: contentfulArticle(slug: { eq: $slug }) {
      authors {
        fullName
        jobPositionTitle
      }
      banner {
        title
        description
        file {
          url
        }
        gatsbyImageData(layout: CONSTRAINED, width: 960)
      }
      bannerCredit
      body {
        raw
        references {
          ...RichTextReferences
        }
      }
      category
      topics
      date
      slug
      sponsorText
      sponsorLogos {
        url
        width
        logoImage {
          file {
            url
          }
          gatsbyImageData
          description
        }
        newTab
      }
      titleDisplay
      titleSeo
      descriptionSeo
      keywordsSeo
      titleBreadcrumb
      sponsorLockupText1
      sponsorLockupLogo1 {
        url
        width
        title
        logoImage {
          description
          gatsbyImageData(height: 60, layout: FIXED)
          file {
            url
          }
        }
        newTab
      }
      sponsorLockupText2
      sponsorLockupLogo2 {
        url
        width
        title
        logoImage {
          description
          gatsbyImageData(height: 60, layout: FIXED)
          file {
            url
          }
        }
        newTab
      }
      sponsorLockupText3
      sponsorLockupLogo3 {
        url
        width
        title
        logoImage {
          description
          gatsbyImageData(height: 60, layout: FIXED)
          file {
            url
          }
        }
        newTab
      }
      sponsorLockupText4
      sponsorLockupLogo4 {
        url
        width
        title
        logoImage {
          description
          gatsbyImageData(height: 60, layout: FIXED)
          file {
            url
          }
        }
        newTab
      }
      actions {
        title
        heading
        copy
        buttonText
        buttonUrl
        newTab
      }
      mediaGallery {
        ...MediaGalleryTypes
      }
    }
    breadCrumbs: contentfulArticle(slug: { eq: $slug }) {
      titleBreadcrumb
      parentPage {
        ...ParentPageRecursive
      }
    }
    contentfulHomePage ( titleDisplay: { eq: "Home Page"} ) {
      alertBox {
        raw
        references {
          ...RichTextReferences
        }
      }
    }
  }
`;

const ArticleTemplate = ({ data }) => {
  const { article, breadCrumbs } = data;
  const sponsorList = article.sponsorLogos
    ? article.sponsorLogos.map((logo) => (
      <SponsorPlug key={uuidv4()} logo={logo} />
    ))
    : null;

  const { alertBoxData, hasAlert } = useAlertBox(data);

  return (
    <StandardPageWrapper
      headProps={getHeadProps(article)}
      alertBoxData={alertBoxData}
      hasAlert={hasAlert}
    >
      <Breadcrumbs parentData={breadCrumbs} />
      <Container className={cx('article-container', 'p-0', 'mb-4', 'mt-0')}>
        <Row className="no-gutters">
          {article.banner && (
            <Col md={12}>
              {article.bannerCredit && (
                <BannerCredit text={article.bannerCredit} />
              )}
              <GatsbyImage
                alt={article.banner.description}
                className="d-block mx-auto"
                data-cy="gatsby-img"
                image={getImage(article.banner)}
              />
            </Col>
          )}
          <Col md={12}>
            <Card className="border-1 px-4 rounded-0">
              <Title title={article.titleDisplay} titleSize="h1" classes="article-title mb-4 mt-3" cypressDataAttr="content-title" />
              <ContentDetails data={article} />
              <div>
                {article.sponsorText && <span style={{ textAlign: 'center' }}>{article.sponsorText}</span>}
                {sponsorList}
              </div>
            </Card>
            <Card className="border-1 px-4 rounded-0 body-card">
              <div className="mb-3 mx-auto">
                {renderRichText(article.body, rendererOptions)}
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      {(article.mediaGallery && <MediaGallery page={article} />)}
      {(article.sponsorLockupText1
          || article.sponsorLockupText2
          || article.sponsorLockupText3
          || article.sponsorLockupText4
          || article.sponsorLockupLogo1
          || article.sponsorLockupLogo2
          || article.sponsorLockupLogo3
          || article.sponsorLockupLogo4)
          && <SponsorLockup page={article} />}
      {article.actions && <CTABanner actions={article.actions} />}
    </StandardPageWrapper>
  );
};

ArticleTemplate.propTypes = {
  // TODO: Fix this eslint warning and remove whitelisting
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default withNullChecks({ dataKey: 'article', typeName: 'article' })(
  ArticleTemplate,
);

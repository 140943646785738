import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const BannerCredit = (props) => {
  const { text } = props;

  return (
    <div className="credit-container">
      <div className="credit-content">
        {text}
      </div>
    </div>
  );
};

BannerCredit.propTypes = {
  text: PropTypes.string.isRequired,
};

export default BannerCredit;

/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faClock, faUser, faCalendarDay, faPlayCircle, faNewspaper, faFlask,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

library.add(faClock, faUser, faCalendarDay, faPlayCircle, faNewspaper, faFlask);

const ContentDetails = (props) => {
  const { data } = props;
  const {
    authors, category, date, topics,
  } = data;

  const categories = {
    activity: { icon: faFlask, label: 'Experiment' },
    blog: { icon: faNewspaper, label: 'Explore' },
    media: { icon: faPlayCircle, label: 'Escape' },
  };

  return (
    <Row className="content-details no-gutters">
      <Col md="auto" xs="auto" className="details-container">
        { category && (
          <span className="detail">
            <FontAwesomeIcon
              icon={categories[category].icon}
              className="icon"
            />
          </span>
        ) }
        <span className="topic">{topics && topics[0] && ` ${topics[0]}`}</span>
        <span className="topic" style={{ margin: 0 }}>{topics && topics[1] && ', '}</span>
        <span className="topic">{topics && topics[1] && `${topics[1]}`}</span>
        <span className="topic" style={{ margin: 0 }}>{topics && topics[2] && topics[2] && ', '}</span>
        <span className="topic">{topics && topics[2] && `${topics[2]}`}</span>
        { authors && (
          <span className="detail" data-cy="author">
            <FontAwesomeIcon icon="user" className="icon" />
            { authors.map((person, index) => (
              <Fragment key={index}>
                { (index > 0) && (
                  <>
                    {' / '}
                  </>
                ) }
                <>
                  {person.fullName}
                </>
                { person.jobPositionTitle && (
                  <>
                    {', '}
                    <span className="secondary">
                      {person.jobPositionTitle}
                    </span>
                  </>
                ) }
              </Fragment>
            ))}
          </span>
        ) }
        { date && (
          <span className="detail" data-cy="publish-date">
            <FontAwesomeIcon icon="calendar-day" className="icon" />
            {moment(date).format('MMM D, YYYY')}
          </span>
        ) }
      </Col>
    </Row>
  );
};

ContentDetails.propTypes = {
  // TODO: Fix this eslint warning and remove whitelisting
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ContentDetails;
